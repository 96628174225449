import Controler from '../base/Controler';
import tippyBuilder from './tippyBuilder';

class TooltipControler extends Controler {
  constructor() {
    super();

    this.buildTooltip();
  }

  buildTooltip(): void {
    this.selectElements('[data-tooltip]').forEach(tt => {
      const { tooltip, tooltipText } = tt.dataset;

      let template = '';

      if (tooltip === 'text-tooltip') {
        template = `
          <div class="tooltip tooltip--text">
            <span>${tooltipText}</span>
          </div>
        `;
      }

      tippyBuilder(tt, template, tooltip);
    });
  }
}

export default TooltipControler;
