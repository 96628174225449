import sticky from 'stickyfilljs';
import Controler from '../base/Controler';

import MarqueeAnimation from './MarqueeAnimation';
import CursorFollower from './CursorFollower';

class AnimationControler extends Controler {
  constructor() {
    super();

    this.marqueeInit();
    this.polyfillSticky();

    const cursorFollower = new CursorFollower('.js-cursorFollower');
  }

  public onScroll(): void {}

  private marqueeInit(): void {
    this.selectElements('.js-marquee').forEach(marquee => {
      const m = new MarqueeAnimation(marquee);
    });
  }

  private polyfillSticky(): void {
    this.selectElements('.sticky').forEach(element => {
      sticky.addOne(element);
    });
  }
}

export default AnimationControler;
