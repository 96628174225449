export interface Validation {
  field: string;
  valid: boolean;
  message?: string;
}

export const validateEmpty = (
  el: HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
): Validation => {
  const validation = {
    field: el.name,
    valid: !!el.value.length
  };
  return validation;
};

export const validateEmail = (el: HTMLInputElement): Validation => {
  const regex = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  const validation = {
    field: el.name,
    valid: regex.test(el.value)
  };
  return validation;
};

export const validateCheckbox = (el: HTMLInputElement): Validation => {
  const validation = {
    field: el.name,
    valid: el.checked
  };
  return validation;
};
