import ScrollTo from './ScrollTo';

class ScrollToControler {
  constructor() {
    const scrollToTop = new ScrollTo('[data-scroll-to-top]', true);
    const scrollToID = new ScrollTo('[data-scroll-to-id]');
  }
}

export default ScrollToControler;
