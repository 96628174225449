import tippyJs from 'tippy.js';

const setupTippies = (
  el: Element,
  template: string,
  option: string,
  container?: HTMLElement,
  options?: {}
): void => {
  let opt = {};

  if (option === 'text-tooltip') {
    opt = {
      placement: 'top',
      flip: false,
      arrow: true,
      animation: 'fade',
      allowHTML: true
    };
  }

  tippyJs(el, {
    animation: 'fade',
    content: template,
    theme: 'white',
    delay: [100, 0],

    ...opt,
    ...options
  });
};

export default setupTippies;
