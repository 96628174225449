/*  eslint import/no-unresolved: "off"  */
import { slideToggle } from 'slidetoggle';

class ShowMore {
  private containerEL: HTMLElement;
  private showAll = false;
  private contentEl: HTMLElement;

  constructor(container: string | HTMLElement) {
    if (typeof container === 'string') {
      this.containerEL = document.querySelector(container);
    } else {
      this.containerEL = container;
    }

    if (this.containerEL) {
      this.init();
    }
  }

  private init(): void {
    this.contentEl = this.containerEL.querySelector('[data-show-more-content]');
    this.hideContent();
    this.initTriggerButton();
  }

  private updateStatus(status: boolean): void {
    this.showAll = status;
    this.containerEL.dataset.showMore = status ? '1' : '0';
  }

  private hideContent(): void {
    this.updateStatus(false);
    slideToggle.slideToggle(this.contentEl, 0);
  }

  private toggleHiddenEls(): void {
    this.updateStatus(!this.showAll);
    slideToggle.slideToggle(this.contentEl, 600);
  }

  private initTriggerButton(): void {
    const button: HTMLElement = this.containerEL.querySelector(
      '[data-show-more-button]'
    );

    if (button) {
      button.addEventListener('click', this.toggleHiddenEls.bind(this));
    }
  }
}

export default ShowMore;
