import Controler from '../base/Controler';
import HomeFeed from './HomeFeed';

class FeedControler extends Controler {
  constructor() {
    super();
    const homeFeed = new HomeFeed('.js-home-feed');
  }
}

export default FeedControler;
