import Controler from '../base/Controler';
import FormLabels from './FormLabels';
import fileUpload from './fileUpload';
import datePicker from './datePicker';
import Form from './Form';

class FormControler extends Controler {
  constructor() {
    super();

    this.init();
  }

  init(): void {
    this.initLabels();
    this.simpleForm();
    this.uploadFiles();
    this.initDatePicker();
  }

  private simpleForm(): Form[] {
    const formArr: Form[] = [];
    const formEls: NodeListOf<HTMLFormElement> = document.querySelectorAll(
      '.js-simple-form'
    );

    formEls.forEach(formEl => {
      formArr.push(new Form(formEl));
    });

    return formArr;
  }

  private initLabels(): FormLabels {
    const els: NodeListOf<
      HTMLInputElement | HTMLTextAreaElement
    > = document.querySelectorAll(
      '.form__textarea, .form__input, .form__select'
    );

    return new FormLabels(els);
  }

  private uploadFiles(): void {
    this.selectElements('.js-file-upload').forEach(el => {
      fileUpload(el);
    });
  }

  private initDatePicker(): void {
    this.selectElements('.js-date-picker').forEach(el => {
      datePicker(el);
    });
  }
}

export default FormControler;
