import MasonryGrid from '../galleryLayout/MasonryGrid';
import Feed from './Feed';
import { SingleFeed } from '../Interfaces';

class HomeFeed extends Feed<SingleFeed> {
  getRenderTemplate(feeds: SingleFeed[]): Element {
    const templateGridSticky = document.createElement('DIV');
    templateGridSticky.classList.add('grid');
    templateGridSticky.classList.add('grid--hasGutter');

    const templateGrid = document.createElement('DIV');
    templateGrid.classList.add('grid--masonry');
    templateGrid.classList.add('grid');

    feeds.forEach(feed => {
      const { type } = feed.sticky;

      switch (type) {
        case 'single':
          templateGrid.insertAdjacentHTML(
            'beforeend',
            ` <div class="grid__cell grid__cell--1-2">
                ${this.buildSingleFeed(feed)}
               </div>`
          );
          break;
        case 'multi':
          templateGridSticky.insertAdjacentHTML(
            'beforeend',
            ` <div class="grid__cell grid__cell--1-2">
                ${this.buildSingleFeed(feed)}
               </div>`
          );
          break;
        case 'full':
          templateGridSticky.insertAdjacentHTML(
            'beforeend',
            ` <div class="grid__cell">
                ${this.buildSingleFeed(feed)}
               </div>`
          );
          break;

        default:
          templateGrid.insertAdjacentHTML(
            'beforeend',
            ` <div class="grid__cell grid__cell--1-4">
                ${this.buildSingleFeed(feed)}
               </div>`
          );
      }
    });

    const wrap = document.createElement('DIV');
    if (templateGridSticky.childElementCount)
      wrap.insertAdjacentElement('beforeend', templateGridSticky);

    wrap.insertAdjacentElement('beforeend', templateGrid);

    return wrap;
  }

  buildSingleFeed(feed: SingleFeed): string {
    const { url, image, sticky, date, title, titleModifierClass, tag } = feed;

    const template = `
    <div class="card card--events ${sticky.modifierClass}">
      <figure class="card__image">
        <a href="${url}"> <img src="${image.url}" alt="${image.alt}"></a>
      </figure>
      <div class="card__content">
      ${
        date || tag
          ? `<span class="card__details">${date}${tag ? ` ${tag}` : ''}</span>`
          : ''
      }
        
        <h3 class="card__title ${titleModifierClass}"><a href="${url}">${title}</a></h3>
      </div>
    </div>
    `;

    return template;
  }

  onRenderCb(): void {
    setTimeout(() => {
      const masonryGrid: HTMLElement = document.querySelector('.grid--masonry');
      new MasonryGrid(masonryGrid);
    }, 10);
  }
}

export default HomeFeed;
