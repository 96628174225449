import animateScrollTo from 'animated-scroll-to';
import Dropdown from './Dropdown';
import HashControler from '../base/HashControler';

class HashedDropdown extends Dropdown {
  private hashControler: HashControler;

  constructor(HTMLElement: HTMLElement, public dropdownsArr: HashedDropdown[]) {
    super(HTMLElement);

    this.hashControler = new HashControler();
    this.checkHashOnLoad();
  }

  checkHashOnLoad(): void {
    const hash = this.hashControler.checkHash(this.getHash);
    if (hash) {
      this.open(0);
      animateScrollTo(this.containerEl, { verticalOffset: -10 });
    }
  }

  onOpen(): void {
    this.hashControler.updateHashLocation(this.getHash);
    this.closeDropdowns();
  }

  onClose(): void {
    const activeDropdowns = this.dropdownsArr.filter(
      d => d.getState === 'open'
    );
    if (!activeDropdowns.length) this.hashControler.removeHash();
  }

  private closeDropdowns(): void {
    const { hash } = window.location;
    const toClose = this.dropdownsArr.filter(
      d => d.getState === 'open' && `#${d.getHash}` !== hash
    );

    toClose.map(c => c.close());
  }
}

export default HashedDropdown;
