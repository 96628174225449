import Controler from '../base/Controler';
import Accessibility from './Accessibility';

class HeaderControler extends Controler {
  constructor() {
    super();
    const a = new Accessibility();
  }
}

export default HeaderControler;
