import ApiSync from '../api/ApiSync';
import { Pagination, FormData, SearchFeed } from '../Interfaces';

const isResponseData = <T>(variable: any): variable is SearchFeed<T> =>
  (variable as SearchFeed<T>).data.feeds !== undefined &&
  (variable as SearchFeed<T>).data.pagination !== undefined;

abstract class Feed<T> {
  private api: ApiSync;
  private el: HTMLElement;
  private filterForm: HTMLFormElement;
  private container: HTMLElement;
  private nextPageButton: HTMLElement;
  private pagination: Pagination = {
    page: 1,
    total: null
  };

  constructor(blockSelector: string) {
    this.el = document.querySelector(blockSelector);

    if (this.el) {
      this.init();
    }
  }

  abstract getRenderTemplate(d: T[]): Element;

  abstract onRenderCb(): void;

  private init(): void {
    this.container = this.el.querySelector('.js-feed-container');
    this.filterForm = this.el.querySelector('form');
    // this.initPagination();
    this.initApi();
    this.initFormSubmiter();
    this.initFilters();
  }

  private initFormSubmiter(): void {
    this.filterForm = this.el.querySelector('form');
    this.filterForm.addEventListener('submit', this.onFormSubmit);
  }

  private initApi(): void {
    const { requestUrl } = this.el.dataset;
    this.api = new ApiSync(requestUrl);
  }

  private onFormSubmit = async (e?: Event): Promise<void> => {
    if (e) e.preventDefault();

    //  get all inputs
    const formInputs = this.filterForm.querySelectorAll(
      'input[name], select[name]'
    ) as NodeListOf<HTMLInputElement | HTMLSelectElement>;

    //  build filters data object
    const filterData = this.getFiltersData(formInputs);

    //  fetch data from api
    const responseData = await this.api.fetch<SearchFeed<T>>({
      ...filterData,
      page: this.pagination.page
    });

    console.log(responseData);

    //  Render data
    if (isResponseData<T>(responseData))
      this.renderContent(responseData.data.feeds, true);
  };

  private initFilters() {
    // const filters = this.el.querySelectorAll(
    //   '.js-show-more--filter input[type="radio"]'
    // );
    // filters.forEach(filter => {
    //   filter.addEventListener('change', this.onFilterChange.bind(this));
    // });
  }

  private getFiltersData(
    inputs: NodeListOf<HTMLInputElement | HTMLSelectElement>
  ): FormData {
    const data: FormData = {};

    inputs.forEach(input => {
      const inputName = input.getAttribute('name');
      const { type } = input;

      if (input.tagName === 'INPUT') {
        switch (type) {
          case 'checkbox':
            data[inputName] = (input as HTMLInputElement).checked ? 1 : 0;
            break;
          default:
            data[inputName] = input.value;
        }
      }

      if (input.tagName === 'SELECT') {
        const selected = input.querySelectorAll('option:checked');

        data[inputName] = Array.prototype.slice
          .call(selected)
          .map((el: HTMLOptionElement) => el.value);
      }
    });

    return data;
  }

  private initPagination(): void {
    // this.nextPageButton = document.querySelector('.js-show-more--next-page');
    // if (this.nextPageButton) {
    //   this.nextPageButton.addEventListener(
    //     'click',
    //     this.onNextPageClick.bind(this)
    //   );
    // }
  }

  // private async onNextPageClick(e: Event): Promise<void> {
  //   e.preventDefault();
  //   const category = this.getFilterValue();
  //   const data = await this.fetchData(
  //     this.pagination.current_page + 1,
  //     category
  //   );
  //   this.renderContent(data.data);
  //   this.updatePagination(data);
  // }

  private async onFilterChange(e: Event): Promise<void> {
    // const category = this.getFilterValue(e);
    // const data = await this.fetchData(1, category);
    // this.renderContent(data.data, true);
    // this.updatePagination(data);
  }

  private renderContent(feeds: T[], clearContainer = false): void {
    if (clearContainer) this.container.innerHTML = '';

    this.container.insertAdjacentElement(
      'beforeend',
      this.getRenderTemplate(feeds)
    );

    this.onRenderCb();
  }

  // private updatePagination(data: ShowMorePagination): void {
  //   this.pagination.current_page = data.current_page;
  //   this.pagination.total_pages = data.total_pages;
  //   this.pagination.posts_per_page = data.posts_per_page;
  //   this.toggleShowMoreButton();
  // }

  // private toggleShowMoreButton(): void {
  //   if (this.pagination.current_page >= this.pagination.total_pages) {
  //     this.nextPageButton.classList.add('button--isDisabled');
  //   } else {
  //     this.nextPageButton.classList.remove('button--isDisabled');
  //   }
  // }
}

export default Feed;
