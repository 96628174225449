import Masonry from 'masonry-layout';

class MasonryGrid {
  constructor(el: HTMLElement) {
    const msnry = new Masonry(el, {
      // options
      itemSelector: '.grid__cell',
      horizontalOrder: true,
      columnWidth: '.grid__cell--1-4',
      gutter: 40,
      percentPosition: true
    });
  }
}

export default MasonryGrid;
