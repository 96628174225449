class FormLabels {
  private activeClassName = 'active';

  constructor(public els: NodeListOf<HTMLInputElement | HTMLTextAreaElement>) {
    this.initEventsListeners();
  }

  private initEventsListeners(): void {
    this.els.forEach(input => {
      input.addEventListener('focus', this.onElementFocus.bind(this));
      input.addEventListener('blur', this.onElementBlur.bind(this));
    });
  }

  private onElementFocus(e: Event): void {
    const target = e.target as HTMLInputElement;
    target.closest('.form__control').classList.add(this.activeClassName);
  }

  private onElementBlur(e: Event): void {
    const target = e.target as HTMLInputElement;
    if (!target.value) {
      target.closest('.form__control').classList.remove(this.activeClassName);
    }
  }
}

export default FormLabels;
