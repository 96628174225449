interface Coordinates {
  x: number;
  y: number;
}

class CursorFollower {
  private blockEl: HTMLElement;
  private minionEL: HTMLElement;
  private minionCenter: Coordinates;

  constructor(blockSelector: string) {
    this.blockEl = document.querySelector(blockSelector);

    if (this.blockEl) {
      this.init();
      this.events();
    }
  }

  private events(): void {
    this.blockEl.addEventListener('mousemove', this.onMosemove);
  }

  private init(): void {
    this.minionEL = document.querySelector('.js-cursorFollower-minion');
    this.minionCenter = this.getCenterCoords;
    this.minionEL.style.transition = 'transform 1000ms ease-out';
  }

  private onMosemove = (e: MouseEvent): void => {
    const { x, y } = this.minionCenter;

    const offsetX = window.innerWidth / 200;
    const offsetY = window.innerHeight / 100;
    const translateX = Math.round((e.clientX - x) / offsetX);
    const translateY = Math.round((e.clientY - x) / offsetY);

    this.minionEL.style.transform = `translate(${translateX}px, ${translateY}px)`;
  };

  get getCenterCoords(): Coordinates {
    const minion = this.minionEL.getBoundingClientRect();
    return {
      x: minion.left + minion.width / 2,
      y: minion.top + minion.height / 2
    };
  }
}

export default CursorFollower;
