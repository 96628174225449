const removeBtn = (
  input: HTMLInputElement,
  cb: () => void
): HTMLButtonElement => {
  const button = document.createElement('button');
  button.type = 'button';
  button.classList.add('file-upload__remove');
  button.addEventListener('click', (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    input.value = '';
    cb();
  });
  return button;
};

const fileUpload = (el: HTMLElement): void => {
  const label = el.querySelector('label');
  const labelVal = label.innerHTML;
  const input: HTMLInputElement = el.querySelector('input[type="file"]');

  const clearLabel = (): void => {
    label.innerHTML = labelVal;
  };

  input.addEventListener('change', (e: Event): void => {
    const target = e.target as HTMLInputElement;
    const fileName = target.files.length ? target.files[0].name : '';

    if (fileName) {
      label.innerHTML = fileName;
      label.insertAdjacentElement('beforeend', removeBtn(target, clearLabel));
    } else {
      clearLabel();
    }
  });
};

export default fileUpload;
