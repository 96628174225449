import ApiSync from '../api/ApiSync';
import { APIResponseStatus, FormData } from '../Interfaces';
import {
  validateCheckbox,
  validateEmail,
  validateEmpty,
  Validation
} from './formValidate';

// declare const animateScrollTo: any;
// require('animated-scroll-to');

interface FormResponseData extends APIResponseStatus {
  message: string;
  fields?: Validation[];
}

const isResponseData = (
  variableToCheck: any
): variableToCheck is FormResponseData =>
  (variableToCheck as FormResponseData).message !== undefined;

class Form {
  public form: HTMLFormElement;
  private api: ApiSync;
  private submitButton: HTMLElement;
  private errorClassName = 'form__error-message';
  private mustValidateClassName = 'js-validate-field';
  private notValidClassName = 'notValid';
  private disableSubmitButtonClassName = 'button--isDisabled';
  private inputContainerClassName = 'form__control';

  constructor(formSselector: string | HTMLFormElement) {
    if (typeof formSselector === 'string') {
      this.form = document.querySelector(formSselector);
    } else {
      this.form = formSselector;
    }

    if (this.form) {
      this.submitButton = this.form.querySelector('button[type="submit"]');
      this.initEventsListeners();
      this.initApi();
    }
  }

  onSuccessCb(): void {
    // animateScrollTo(this.form.parentNode, {
    //   speed: 350
    // });
  }

  private async onFormSubmit(e: Event): Promise<void> {
    e.preventDefault();

    //  get all inputs
    const formInputs = this.form.querySelectorAll(
      'input[name], textarea[name], select[name]'
    ) as NodeListOf<HTMLInputElement | HTMLTextAreaElement>;

    //  clear error messages
    this.form.querySelectorAll(`.${this.notValidClassName}`).forEach(error => {
      error.classList.remove(this.notValidClassName);
    });

    // validate form
    const formValid = this.validate(formInputs);

    if (formValid) {
      this.disableFormSubmit();
      const data = this.getFormData(formInputs);

      const responseData = await this.api.save<
        FormResponseData | Error,
        FormData
      >(data);

      //  Type guard for Api return. Validate only response data.
      if (isResponseData(responseData)) this.validateResponse(responseData);
    }
  }

  private getFormData(
    inputs: NodeListOf<HTMLInputElement | HTMLTextAreaElement>
  ): FormData {
    const data: FormData = {};

    inputs.forEach(input => {
      const inputName = input.getAttribute('name');
      const { type } = input;

      switch (type) {
        case 'checkbox':
          data[inputName] = (input as HTMLInputElement).checked ? 1 : 0;
          break;
        default:
          data[inputName] = input.value;
      }
    });

    return data;
  }

  private validateResponse(resp: FormResponseData): void {
    if (resp.status === 'warning') {
      this.enableFormSubmit();
      //  Show global error message
      // this.form.insertAdjacentHTML(
      //     'beforeend',
      //     `<div class="${this.errorClassName}"><p>${resp.message}</p></div>`
      // );

      //  Markup fields with errors
      resp.fields.forEach(error => {
        this.toggleErrors(error);
      });
    } else if (resp.status === 'success') {
      this.onSuccessCb();
      this.showSuccessMessage(resp.message);
    } else {
      this.enableFormSubmit();
      // alert('Opps, something went wrong! Please try again'); // eslint-disable-line no-undef
    }
  }

  private disableFormSubmit(): void {
    this.submitButton.classList.add(this.disableSubmitButtonClassName);
  }

  private enableFormSubmit(): void {
    this.submitButton.classList.remove(this.disableSubmitButtonClassName);
  }

  showSuccessMessage(message: string): void {
    const parent = this.form.parentElement;
    parent.innerHTML = '';
    parent.insertAdjacentHTML(
      'beforeend',
      `
            <div class="form__success">
                <div class="form__success-message">
                   ${message}
                </div>
            </div>
        `
    );
  }

  validate(
    inputs: NodeListOf<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ): boolean {
    const validation: Validation[] = [];

    inputs.forEach(input => {
      if (input.classList.contains(this.mustValidateClassName)) {
        const { type } = input;

        switch (type) {
          case 'select-one':
            validation.push(validateEmpty(input as HTMLSelectElement));
            break;
          case 'text':
            validation.push(validateEmpty(input as HTMLInputElement));
            break;
          case 'textarea':
            validation.push(validateEmpty(input as HTMLTextAreaElement));
            break;
          case 'email':
            validation.push(validateEmail(input as HTMLInputElement));
            break;
          case 'checkbox':
            validation.push(validateCheckbox(input as HTMLInputElement));
            break;
          default:
        }
      }
    });

    validation.forEach(validationObj => {
      this.toggleErrors(validationObj);
    });

    return validation.length ? !validation.some(x => x.valid === false) : true;
  }

  private toggleErrors(validation: Validation): void {
    const el = this.form.querySelector(
      `[name="${validation.field}"]`
    ) as HTMLElement;

    if (el) {
      if (!validation.valid) {
        this.addErrorClass(el);
      } else {
        this.removeErrorClass(el);
      }
    }
  }

  private addErrorClass(el: HTMLElement): void {
    el.closest(`.${this.inputContainerClassName}`).classList.add(
      this.notValidClassName
    );
  }

  private removeErrorClass(el: HTMLElement): void {
    el.closest(`.${this.inputContainerClassName}`).classList.remove(
      this.notValidClassName
    );
  }

  private initApi(): void {
    const { requestUrl } = this.form.dataset;
    this.api = new ApiSync(requestUrl);
  }

  private initEventsListeners(): void {
    this.form.addEventListener('submit', this.onFormSubmit.bind(this));
  }
}

export default Form;
