import inViewport from '../../addons/inViewport';

class MarqueeAnimation {
  private innerBlocks: NodeListOf<HTMLElement>;

  constructor(public el: HTMLElement) {
    this.innerBlocks = this.el.querySelectorAll('.marquee__inner');
    if (this.innerBlocks.length) {
      this.init();
      this.events();
    }
  }

  private init(): void {
    this.innerBlocks.forEach(iB => {
      const { direction } = iB.dataset;
      const textElement = iB.querySelector('.marquee__text');

      if (direction === 'to-left') this.cloneText(iB, textElement, 'beforeend');

      if (direction === 'to-right')
        this.cloneText(iB, textElement, 'afterbegin');

      setTimeout(() => {
        iB.style.transition = 'transform 200ms linear';
      }, 100);
    });
  }

  private cloneText(
    container: Element,
    el: Element,
    position: InsertPosition
  ): void {
    const clone = el.cloneNode(true) as Element;
    clone.classList.add('cloned');
    container.insertAdjacentElement(position, clone);
  }

  private events(): void {
    window.addEventListener('resize', this.onResize);
    window.addEventListener('scroll', this.onScroll);
  }

  private onScroll = (e: Event): void => {
    const marqueeInView = inViewport(this.el, { offset: 1 });

    if (marqueeInView) {
      this.innerBlocks.forEach(block => {
        const { direction } = block.dataset;
        const top = window.pageYOffset;

        if (direction === 'to-left') this.translateBlock(block, top * 0.22);

        if (direction === 'to-right') this.translateBlock(block, top * -0.22);
      });
    }
  };

  private translateBlock(el: HTMLElement, val: number): void {
    el.style.transform = `translateX(${val}px)`;
  }

  private onResize = (e: Event): void => {
    this.onScroll(e);
  };
}

export default MarqueeAnimation;
