import '../scss/style.scss';
import { nodeListForEach } from './addons/polyfills';
import appLoaded from './addons/appLoaded';
import FormControler from './modules/form/FormControler';
import ModalControler from './modules/modal/ModalControler';
import ShowMoreControler from './modules/showMore/ShowMoreControler';
import DropdownControler from './modules/dropdown/DropdownControler';
import GalleryLayoutControler from './modules/galleryLayout/GalleryLayoutControler';
import FilterControler from './modules/filter/FilterControler';
import SelectControler from './modules/select/SelectControler';
import HeaderControler from './modules/header/HeaderControler';
import PopupControler from './modules/popup/PopupControler';
import AnimationControler from './modules/animation/AnimationControler';
import TooltipControler from './modules/tooltip/TooltipControler';
import ScrollToControler from './modules/scrollTo/ScrollToControler';
import FeedControler from './modules/Feed/FeedControler';

const inits = (): void => {
  nodeListForEach();
  const showMoreControler = new ShowMoreControler();
  const formControler = new FormControler();
  const modalControler = new ModalControler();
  const dropdownControler = new DropdownControler();
  const galleryLayoutControler = new GalleryLayoutControler();
  const filterControler = new FilterControler();
  const selectControler = new SelectControler();
  const headerControler = new HeaderControler();
  const popupControler = new PopupControler();
  const animationControler = new AnimationControler();
  const tooltipControler = new TooltipControler();
  const scrollToControler = new ScrollToControler();
  const feedControler = new FeedControler();
};

const onScroll = (e: Event): void => {};

const onResize = (e: Event): void => {
  onScroll(e);
};

const onLoad = (e: Event): void => {
  inits();
  onResize(e);

  setTimeout(() => {
    appLoaded();
  }, 0);
};

window.addEventListener('load', onLoad);
window.addEventListener('resize', onResize);
window.addEventListener('scroll', onScroll);
