import Popup from '../popup/Popup';

class Accessibility {
  private popup: Popup;

  constructor() {
    this.init();
  }

  private init(): void {
    this.popup = new Popup('.js-accesibility-cookie');

    const buttons: NodeListOf<HTMLButtonElement> = document.querySelectorAll(
      '.accessibility__option'
    );

    buttons.forEach(btn => {
      btn.addEventListener('click', e => {
        btn.parentElement.classList.remove('active');
        const button = e.target as HTMLButtonElement;
        this.onClick(buttons, button);
      });
    });
  }

  private onClick = (
    buttons: NodeListOf<HTMLElement>,
    button: HTMLElement
  ): void => {
    buttons.forEach(btn => {
      btn.parentElement.classList.remove('active');

      if (btn.dataset.popupValue === button.dataset.popupValue) {
        btn.parentElement.classList.add('active');
        const html = document.querySelector('html') as HTMLHtmlElement;
        html.dataset.accessibility = button.dataset.popupValue;
      }
    });
  };
}

export default Accessibility;
