import flatpickr from 'flatpickr';

const datePicker = (el: HTMLElement): void => {
  // flatpickr.l10ns.default.firstDayOfWeek = 1;
  flatpickr(el, {
    enableTime: false
  });
};

export default datePicker;
