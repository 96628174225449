class RadioFilter {
  private radioInputs: NodeListOf<HTMLInputElement>;
  private contentArr: HTMLInputElement[] = [];
  private filterName: string;
  private contentContainer: HTMLElement;

  constructor(public filterContainer: HTMLElement) {
    this.radioInputs = this.filterContainer.querySelectorAll(
      'input[type="radio"]'
    );

    if (this.radioInputs.length) this.init();
  }

  private init(): void {
    this.selectContent();

    this.filterName = this.getFilterDataName();

    this.radioInputs.forEach(input => {
      input.addEventListener('change', this.onFilterChange);
    });
  }

  private onFilterChange = (): void => {
    this.radioInputs.forEach(input => {
      if (input.checked) {
        const list = this.filterNodes(input.value);
        this.renderList(list);
      }
    });
  };

  private selectContent(): void {
    const { name } = this.radioInputs[0];
    const contentNodes = this.filterContainer.querySelectorAll(
      `[data-${name}]`
    );
    this.contentArr = Array.prototype.slice.call(contentNodes);

    this.contentContainer = this.filterContainer.querySelector(
      '.js-filter-content-container'
    );
  }

  private filterNodes = (filterValue: string): HTMLElement[] => {
    return filterValue === 'all'
      ? this.contentArr
      : this.contentArr.filter(
          el => el.dataset[this.filterName] === filterValue
        );
  };

  private renderList(list: HTMLElement[]): void {
    this.contentContainer.innerHTML = '';
    list.map(el => this.renderEl(el));
  }

  private renderEl(el: HTMLElement): void {
    // const clone = el.cloneNode(true) as Element;
    // clone.classList.add('isHidden');

    // setTimeout(() => {
    //   clone.classList.add('fadeIn');
    // }, 0);
    this.contentContainer.insertAdjacentElement('beforeend', el);
  }

  private getFilterDataName(): string {
    const { name } = this.radioInputs[0];
    const nameArr = name.split('-');
    const nameAdapted = name
      .split('-')
      .filter((a, i) => i > 0)
      .map(s => s.charAt(0).toUpperCase() + s.slice(1))
      .join('');

    return `${nameArr[0]}${nameAdapted}`;
  }
}

export default RadioFilter;
