interface Options {
  offset: number;
}

const isInViewport = (elem: Element, options?: Options): boolean => {
  const opt: Options = {
    offset: 0,
    ...options
  };

  const distance = elem.getBoundingClientRect();
  const offset = distance.height * opt.offset;

  return (
    distance.top + offset >= 0 &&
    distance.left >= 0 &&
    distance.bottom + offset <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    distance.right <=
      (window.innerWidth || document.documentElement.clientWidth)
  );
};

export default isInViewport;
