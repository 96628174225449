import Controler from '../base/Controler';
import FormLabels from '../form/FormLabels';
import Selectize from './selectize';

class SelectControler extends Controler {
  constructor() {
    super();
    const s = new Selectize();

    // this.initSingleSelect();
  }
}

export default SelectControler;
