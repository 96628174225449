class HashControler {
  public updateHashLocation(hash: string): void {
    // window.location.hash = hash;
    window.history.pushState(null, null, `#${hash}`);
  }

  public removeHash(): void {
    const noHashURL = window.location.href.replace(/#.*$/, '');
    window.history.replaceState('', document.title, noHashURL);
  }

  public checkHash(hashID: string): boolean {
    const { hash } = window.location;
    return hash.substring(1) === hashID;
  }
}

export default HashControler;
