import Controler from '../base/Controler';
import RadioFilter from './RadioFilter';

class FilterControler extends Controler {
  constructor() {
    super();

    this.initRadioFilter();
  }

  private initRadioFilter(): RadioFilter[] {
    const filterArr: RadioFilter[] = [];
    const els = this.selectElements('.js-radio-filter');

    els.forEach(el => {
      const radioFilter = new RadioFilter(el);
      filterArr.push(radioFilter);
    });

    return filterArr;
  }
}

export default FilterControler;
