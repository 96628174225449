import Controler from '../base/Controler';
import MasonryGrid from './MasonryGrid';

class GalleryLayoutControler extends Controler {
  constructor() {
    super();

    this.init();
  }

  init(): void {
    this.selectElements('.js-masonry-grid').forEach(el => new MasonryGrid(el));
  }
}

export default GalleryLayoutControler;
