import * as qs from 'qs';
import axios, { AxiosResponse } from 'axios';
import { IObject } from '../Interfaces';

class ApiSync {
  private lang: string;

  constructor(public url: string) {
    this.lang = document.body.dataset.lang;
  }

  public fetch<T>(params?: IObject): Promise<T> | Error {
    try {
      return axios({
        url: this.url,
        method: 'get',
        params: {
          lang: this.lang,
          ...params
        }
      }).then((response: AxiosResponse<T>) => response.data);
    } catch (error) {
      return error;
    }
  }

  public save<T, K>(data: K): Promise<T> | Error {
    try {
      return axios({
        url: this.url,
        method: 'post',
        params: {
          lang: this.lang
        },
        data: qs.stringify({
          ...data
        }),
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then((response: AxiosResponse<T>) => response.data);
    } catch (error) {
      return error;
    }
  }

  public delete<T, K>(data: K): Promise<T> | Error {
    try {
      return axios({
        url: this.url,
        method: 'delete',
        params: {
          lang: this.lang
        },
        data: qs.stringify({
          ...data
        }),
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then((response: AxiosResponse<T>) => response.data);
    } catch (error) {
      return error;
    }
  }
}

export default ApiSync;
