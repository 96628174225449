/*  eslint import/no-unresolved: "off"  */
import { slideToggle } from 'slidetoggle';

type State = 'open' | 'closed';
type Animation = 'slide' | 'fade';

class Dropdown {
  private state: State = 'closed';
  public containerEl: HTMLElement;
  private buttonSelector = '.js-dropdown-button';
  private contentSelector = '.js-dropdown-content';
  private button: HTMLElement;
  private content: HTMLElement;

  constructor(
    container: string | HTMLElement,
    public animation: Animation = 'slide',
    public speed = 500
  ) {
    if (typeof container === 'string') {
      this.containerEl = document.querySelector(container);
    } else {
      this.containerEl = container;
    }

    if (this.containerEl) {
      this.init();
      this.setState('closed');
    }
  }

  //  Initial dropdown events and esentials setup
  init(): void {
    this.button = this.containerEl.querySelector(this.buttonSelector);
    this.content = this.containerEl.querySelector(this.contentSelector);

    if (this.button && this.content) {
      this.button.addEventListener('click', this.toggle.bind(this));
    }

    if (this.animation === 'fade') {
      this.containerEl.classList.add('fade-animation');
    }
  }

  //  Open dropdown content
  open(speed = this.speed): void {
    this.setState('open');
    if (this.animation === 'slide') this.slideToggle();

    if (this.animation === 'fade') this.containerEl.classList.add('fade-in');

    this.onOpen();
  }

  //  Close dropdown content
  close(): void {
    this.setState('closed');
    if (this.animation === 'slide') this.slideToggle();

    if (this.animation === 'fade') this.containerEl.classList.remove('fade-in');

    this.onClose();
  }

  //  Toggle dropdown content
  toggle(): void {
    if (this.state === 'open') {
      this.close();
    } else {
      this.open();
    }
  }

  get getState(): State {
    return this.state;
  }

  get getHash(): string | null {
    return this.containerEl.id ? this.containerEl.id : null;
  }

  //  Change dropdown state
  private setState(state: State): void {
    this.state = state;
    this.containerEl.dataset.dropdownState = state;
  }

  //  Slide content with transition
  private slideToggle(): void {
    const el = slideToggle.slideToggle(this.content, this.speed);
  }

  // Callback on dropdown Open
  public onOpen(): void {}

  // Callback on dropdown close
  public onClose(): void {}
}

export default Dropdown;
