import $ from 'jquery';

require('selectize');

class SelectizeComponent {
  constructor() {
    this.init();
  }

  init() {
    $('.js-selectize-single').selectize({
      onDropdownOpen($dropdown) {
        $dropdown.closest('.form__control').addClass('dropdownOpen');
      },
      onDropdownClose($dropdown) {
        $dropdown.closest('.form__control').removeClass('dropdownOpen');
      }
    });

    $('.js-selectize-multiple').selectize({
      onDropdownOpen($dropdown) {
        $dropdown.closest('.form__control').addClass('dropdownOpen');
      },
      onDropdownClose($dropdown) {
        $dropdown.closest('.form__control').removeClass('dropdownOpen');
      },
      plugins: ['remove_button'],
      delimiter: ',',
      hideSelected: false,
      persist: false,
      create(input) {
        return {
          value: input,
          text: input
        };
      },

      onItemRemove(val) {
        $(this.$dropdown)
          .find(`[data-value="${val}"]`)
          .removeClass('selected');
      }
    });

    this.labelControl();

    //  Triger change to check if select has value on init
    $('select').trigger('change');
  }

  labelControl() {
    $(document).on('focus', '.selectize-input', function() {
      $(this)
        .closest('.form__control')
        .addClass('active');
    });

    $(document).on('blur', '.selectize-input', function() {
      $(this)
        .closest('.form__control')
        .removeClass('active');
    });

    $(document).on('change', '.selectize-input', function() {
      $(this)
        .closest('.form__control')
        .removeClass('active');
    });

    $(document).on('change', 'select', e => {
      this.checkInput(e.target);
    });
  }

  checkInput(el) {
    if (el.tagName === 'SELECT') {
      const $selectize = $(el).closest('.form__control');

      if ($(el).val().length) $selectize.addClass('selected');
      else $selectize.removeClass('selected');
    }
  }
}

export default SelectizeComponent;
