import Controler from '../base/Controler';
import Dropdown from './Dropdown';
import HashedDropdown from './HashedDropdown';

class DropdownControler extends Controler {
  constructor() {
    super();
    this.initClickableDropdowns();
    this.initHashedDropdown();

    // this.initFilterDropdowns();
  }

  private initClickableDropdowns(): Dropdown[] {
    const dropdownsArr: Dropdown[] = [];
    const els = this.selectElements('.js-clickable-dropdown');

    els.forEach(el => {
      const dropdown = new Dropdown(el);
      dropdownsArr.push(dropdown);
    });

    return dropdownsArr;
  }

  private initHashedDropdown(): HashedDropdown[] {
    const dropdownsArr: HashedDropdown[] = [];
    const els = this.selectElements('.js-clickable-dropdown-has-hash');

    els.forEach(el => {
      const dropdown = new HashedDropdown(el, dropdownsArr);
      dropdownsArr.push(dropdown);
    });

    return dropdownsArr;
  }
}

export default DropdownControler;
