import Popup from './Popup';

class PopupControler {
  private popups: Popup[] = [];

  constructor() {
    (document.querySelectorAll('.js-popup') as NodeListOf<HTMLElement>).forEach(
      popup => {
        this.popups.push(new Popup(popup));
      }
    );
  }
}

export default PopupControler;
